const state = () => ({
  group: null
})
const getters = {
  group: state => state.group
}
const mutations = {
  receiveGroup(state, group) {
    state.group = group
  }
}
const actions = {
  getGroup({ commit, rootState, rootGetters }, groupId) {
    return this.$axios
      .post('/bodygram-backend/v1/core/groups/get', {
        accessToken: rootState.user.token,
        id: groupId
      })
      .then(response => {
        if (
          window.$nuxt.$platform === 'electron' &&
          rootGetters['network/onLine']
        ) {
          this.$axios.post(
            `${rootGetters['network/localAPI']}/bodygram-backend/v1/core/groups/get`,
            {
              id: groupId
            }
          )
        }
        commit('receiveGroup', response.data.group)
      })
  },
  updateGroup({ dispatch, rootState, rootGetters }, group) {
    return this.$axios
      .post('/bodygram-backend/v1/core/groups/update', {
        accessToken: rootState.user.token,
        group: {
          id: group.id,
          name: group.name,
          description: group.description
        }
      })
      .then(() => {
        if (
          window.$nuxt.$platform === 'electron' &&
          rootGetters['network/onLine']
        ) {
          this.$axios.post(
            `${rootGetters['network/localAPI']}/bodygram-backend/v1/core/groups/update`,
            {
              silent: true,
              group: {
                id: group.id,
                name: group.name,
                description: group.description
              }
            }
          )
        }
        dispatch('getGroup', group.id)
      })
  },
  deleteGroup({ dispatch, rootState, rootGetters }, groupId) {
    return this.$axios
      .post('/bodygram-backend/v1/core/groups/delete', {
        accessToken: rootState.user.token,
        id: groupId
      })
      .then(() => {
        if (
          window.$nuxt.$platform === 'electron' &&
          rootGetters['network/onLine']
        ) {
          this.$axios.post(
            `${rootGetters['network/localAPI']}/bodygram-backend/v1/core/groups/delete`,
            {
              silent: true,
              id: groupId
            }
          )
        }
      })
  }
}
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}

import dayjs from 'dayjs'

const state = () => ({
  patient: null,
  levels: null,
  exams: null,
  scores: null,
  sortedExams: null
})
const getters = {
  patient: state => state.patient,
  levels: state => state.levels,
  dateOfBirth: state => {
    return state.patient ? dayjs(state.patient.dateOfBirth).format('L') : null
  },
  exams: state => state.exams,
  scores: state => state.scores,
  sortedExams: state => state.sortedExams,
  selectedExam: state => {
    return state.exams ? state.exams.find(x => x.selected === true) : null
  },
  baselineExam: state => {
    return state.exams ? state.exams.find(x => x.isBaseline === true) : null
  }
}
const mutations = {
  receivePatient(state, patient) {
    state.patient = patient
  },
  receiveLevels(state, levels) {
    state.levels = levels
  },
  receiveExams(state, exams) {
    state.exams = exams
  },
  receiveScores(state, scores) {
    state.scores = scores
  },
  receiveSortedExams(state, exams) {
    state.sortedExams = exams
  }
}
const actions = {
  getLevels({ commit, rootState }) {
    return this.$axios
      .post('/bodygram-backend/v1/settings/levels/get', {
        accessToken: rootState.user.token
      })
      .then(response => {
        commit('receiveLevels', response.data.levels)
      })
  },
  getPatient({ commit, rootState, rootGetters }, patientId) {
    return this.$axios
      .post('/bodygram-backend/v1/core/patients/get', {
        accessToken: rootState.user.token,
        id: patientId
      })
      .then(response => {
        if (
          window.$nuxt.$platform === 'electron' &&
          rootGetters['network/onLine']
        ) {
          this.$axios.post(
            `${rootGetters['network/localAPI']}/bodygram-backend/v1/core/patients/get`,
            {
              id: patientId
            }
          )
        }
        commit('receivePatient', response.data.patient)
        commit('group/receiveGroup', response.data.patient.group, {
          root: true
        })
      })
  },
  createPatient({ dispatch, rootState, rootGetters }, patient) {
    return this.$axios
      .post('/bodygram-backend/v1/core/patients/create', {
        accessToken: rootState.user.token,
        patient: patient
      })
      .then(response => {
        if (
          window.$nuxt.$platform === 'electron' &&
          rootGetters['network/onLine']
        ) {
          this.$axios.post(
            `${rootGetters['network/localAPI']}/bodygram-backend/v1/core/patients/create`,
            {
              silent: true,
              id: response.data.patient.id,
              patient: patient
            }
          )
        }
        dispatch('patients/getPatients', patient.groupId, { root: true })
        return response
      })
  },
  updatePatient({ dispatch, rootState, rootGetters }, patient) {
    return this.$axios
      .post('/bodygram-backend/v1/core/patients/update', {
        accessToken: rootState.user.token,
        patient: patient
      })
      .then(() => {
        if (
          window.$nuxt.$platform === 'electron' &&
          rootGetters['network/onLine']
        ) {
          this.$axios.post(
            `${rootGetters['network/localAPI']}/bodygram-backend/v1/core/patients/update`,
            {
              silent: true,
              patient: patient
            }
          )
        }
        dispatch('getPatient', patient.id)
      })
  },
  deletePatient({ dispatch, rootState, rootGetters }, patientId) {
    return this.$axios
      .post('/bodygram-backend/v1/core/patients/delete', {
        accessToken: rootState.user.token,
        id: patientId
      })
      .then(() => {
        if (
          window.$nuxt.$platform === 'electron' &&
          rootGetters['network/onLine']
        ) {
          this.$axios.post(
            `${rootGetters['network/localAPI']}/bodygram-backend/v1/core/patients/delete`,
            {
              silent: true,
              id: patientId
            }
          )
        }
      })
  },
  moveToGroup({ dispatch, rootState, rootGetters }, { groupId, patientId }) {
    return this.$axios
      .post('/bodygram-backend/v1/core/patients/move-to', {
        accessToken: rootState.user.token,
        patientId: patientId,
        groupId: groupId
      })
      .then(() => {
        if (
          window.$nuxt.$platform === 'electron' &&
          rootGetters['network/onLine']
        ) {
          this.$axios.post(
            `${rootGetters['network/localAPI']}/bodygram-backend/v1/core/patients/move-to`,
            {
              silent: true,
              patientId: patientId,
              groupId: groupId
            }
          )
        }
      })
  },
  getExams({ commit, rootState }, patientId) {
    return this.$axios
      .post('/bodygram-backend/v1/app/exams/list', {
        accessToken: rootState.user.token,
        patientId: patientId
      })
      .then(response => {
        const exams = response.data.exams
        if (exams.length) {
          exams.forEach(exam => {
            exam.dateTime = dayjs(exam.effectiveAt).format('L LT')
            exam.selected = false
          })
          const baseline = exams.splice(
            exams.indexOf(exams.find(exam => exam.isBaseline)),
            1
          )
          exams.unshift(...baseline)
          exams[0].selected = true
        }
        commit('receiveExams', exams)
      })
  },
  getScores({ commit, rootState }, patientId) {
    return this.$axios
      .post('/bodygram-backend/v1/app/scores/list', {
        accessToken: rootState.user.token,
        patientId: patientId
      })
      .then(response => {
        const scores = response.data.scores
        if (scores.length) {
          scores.forEach(exam => {
            exam.dateTime = dayjs(exam.effectiveAt).format('L LT')
            exam.selected = false
          })
          scores.selected = true
        }
        commit('receiveScores', scores)
      })
  },
  getGrid({ commit, rootState }, patientId) {
    return this.$axios
      .post('/bodygram-backend/v1/app/exams/grid-view', {
        accessToken: rootState.user.token,
        patientId: patientId,
        limit: -1
      })
      .then(response => {
        const exams = response.data.exams
        if (exams.length) {
          const baseline = exams.splice(
            exams.indexOf(exams.find(exam => exam.isBaseline)),
            1
          )
          exams.unshift(...baseline)
        }
        commit('receiveExams', exams)
      })
  },
  getSortedExams({ commit, rootState }, patientId) {
    return this.$axios
      .post('/bodygram-backend/v1/app/exams/list', {
        accessToken: rootState.user.token,
        patientId: patientId
      })
      .then(response => {
        const exams = response.data.exams
        if (exams.length) {
          exams.forEach(exam => {
            exam.dateTime = dayjs(exam.effectiveAt).format('L LT')
            exam.selected = false
            exam.calculations.weight = exam.weight
          })
        }
        const sortedExams = exams.sort((a, b) => {
          if (dayjs(a.effectiveAt).toDate() > dayjs(b.effectiveAt).toDate()) {
            return 1
          }
          if (dayjs(a.effectiveAt).toDate() < dayjs(b.effectiveAt).toDate()) {
            return -1
          }
          return 0
        })
        const baseline = sortedExams.splice(
          sortedExams.indexOf(sortedExams.find(exam => exam.isBaseline)),
          1
        )
        sortedExams.unshift(...baseline)
        commit('receiveSortedExams', sortedExams)
      })
  }
}
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
